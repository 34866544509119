import type { SelectedFavorites } from '../reducers/favorite-destinations'

type OpenModal = {
  type: 'favorite-destinations/OPEN_MODAL'
}
type CloseModal = {
  type: 'favorite-destinations/CLOSE_MODAL'
}
type SetSelected = {
  type: 'favorite-destinations/SET_SELECTED'
  selectedFavorites: SelectedFavorites
}

export type Action = OpenModal | CloseModal | SetSelected

export const OPEN_MODAL = 'favorite-destinations/OPEN_MODAL'
export const CLOSE_MODAL = 'favorite-destinations/CLOSE_MODAL'
export const SET_SELECTED = 'favorite-destinations/SET_SELECTED'

export const openModal = () => ({
  type: OPEN_MODAL,
})
export const closeModal = () => ({
  type: CLOSE_MODAL,
})

export const setSelected = (
  selectedFavorites: SelectedFavorites | null | undefined,
) => ({
  type: SET_SELECTED,
  selectedFavorites: selectedFavorites,
})
