import { addFlashMessage } from '~/actions/flash-message'
import reservationsDeserializer from '~/deserializers/reservations'
import { i18n } from '~/i18n'
import getCustomerAPI from '~/utils/api/get-customer-api'
import { getReservations } from '~/utils/api/reservations'
import { reportGroupedError } from '~/utils/logger'

import type { Reservation } from '~/types'

export const LOAD_RESERVATIONS_START = 'reservations/LOAD_START'
export const LOAD_RESERVATIONS_SUCCESS = 'reservations/LOAD_SUCCESS'
export const LOAD_RESERVATIONS_FAIL = 'reservations/LOAD_FAIL'
export type ReservationActions =
  | {
      type: 'reservations/LOAD_START'
    }
  | {
      type: 'reservations/LOAD_SUCCESS'
      data: Reservation[]
    }
  | {
      type: 'reservations/LOAD_FAIL'
    }

export function loadReservations({
  reload,
}: {
  reload?: boolean
} = {}) {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const {
      reservations: { loaded, loading },
    } = getState()

    if (loading || (loaded && !reload)) return

    dispatch({
      type: LOAD_RESERVATIONS_START,
    })

    try {
      const api = getCustomerAPI()
      const { data } = await getReservations(api)

      dispatch({
        type: LOAD_RESERVATIONS_SUCCESS,
        data: reservationsDeserializer(data),
      })
    } catch (err) {
      reportGroupedError('loadReservations', err)
      dispatch({
        type: LOAD_RESERVATIONS_FAIL,
      })
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.hooks.reservations.error_flash_message'),
        ),
      )
    }
  }
}
