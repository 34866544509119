/**
 * Reducer for state related to ticket finding.
 */
import {
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
} from '../actions/session'

import type { Actions } from '../actions/session'

export type State = {
  lastUpdated?: number
  updating: boolean
}

const initialState = {
  lastUpdated: Date.now(),
  updating: false,
}

export default function session(state: State = initialState, action: Actions) {
  switch (action.type) {
    case UPDATE_SESSION_REQUEST:
      return { ...state, updating: true }

    case UPDATE_SESSION_SUCCESS:
      return { ...state, lastUpdated: action.lastUpdated, updating: false }

    case UPDATE_SESSION_FAILURE:
      return { ...state, updating: false }

    default:
      return state
  }
}
