/**
 * Defines actions and action creators related to onboarding.
 */
import { push } from '~/actions/navigation'
import onboardingDeserializer from '~/deserializers/onboarding'
import changeLocation from '~/utils/change-location'
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import type { Onboarding, ValueOf } from '~/types'

type Dispatch = (...args: Array<any>) => any

export const onboardingStatus = {
  LOADING: 'LOADING',
  FAILED: 'FAILED',
  LOADED: 'LOADED',
  LOCKED: 'LOCKED',
}

type OnboardingStatusValues = ValueOf<typeof onboardingStatus>

export type OnboardingActions =
  | {
      type: 'ONBOARDING_LOADED'
      data: Onboarding
    }
  | {
      type: 'UPDATE_ONBOARDING_STATUS'
      status: OnboardingStatusValues
    }
  | {
      type: 'ONBOARDING_RESET'
    }

export const ONBOARDING_LOADED = 'ONBOARDING_LOADED'
export function onboardingLoaded(onboarding: Onboarding) {
  return {
    type: ONBOARDING_LOADED,
    data: onboarding,
  }
}

export const ONBOARDING_RESET = 'ONBOARDING_RESET'
export function resetOnboarding() {
  return {
    type: ONBOARDING_RESET,
  }
}

export const UPDATE_ONBOARDING_STATUS = 'UPDATE_ONBOARDING_STATUS'
export function updateOnboardingStatus(status: OnboardingStatusValues) {
  return {
    type: UPDATE_ONBOARDING_STATUS,
    status: status,
  }
}

//
// - Async Action Creators
//

/**
 * loadOnboarding loads the basic pii of the onboarding profile
 */
export function loadOnboarding(token: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateOnboardingStatus(onboardingStatus.LOADING))
      const resp = await authedFetch(`/api/v2/onboardings/${token}`)

      if (resp.ok) {
        const { data } = await resp.json()
        dispatch(onboardingLoaded(onboardingDeserializer(data, token)))
      } else if (resp.status === 404) {
        changeLocation('/onboarding/not-found')
      } else {
        const text = await resp.text()
        throw new HTTPError('Unable to find onboarding', resp, text)
      }
    } catch (error) {
      reportGroupedError('loadOnboarding', error)
      dispatch(updateOnboardingStatus(onboardingStatus.FAILED))
    }
  }
}

/**
 * verifyOnboarding loads the detailed pii of the onboarding profile if the
 * dob provided matches with the onboarding record.
 */
export function verifyOnboarding(token: string, dob: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateOnboardingStatus(onboardingStatus.LOADING))
      const endpoint = `/api/v2/onboardings/${token}`
      const resp = await authedFetch(`${endpoint}?dob=${dob}`)

      if (resp.ok) {
        const { data } = await resp.json()
        dispatch(onboardingLoaded(onboardingDeserializer(data, token)))
        dispatch(push('/onboarding/create-account'))
      } else {
        const text = await resp.text()
        throw new HTTPError('Unable to verify', resp, text)
      }
    } catch (error) {
      reportGroupedError('verifyOnboarding', error)
      const { status } = error.context

      if (status === 429) {
        return dispatch(updateOnboardingStatus(onboardingStatus.LOCKED))
      }

      dispatch(updateOnboardingStatus(onboardingStatus.FAILED))
    }
  }
}
