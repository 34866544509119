import {
  LOAD_RESERVATIONS_START,
  LOAD_RESERVATIONS_SUCCESS,
  LOAD_RESERVATIONS_FAIL,
} from '~/actions/reservations'

import type { AppActions } from '~/actions/types'
import type { Reservation } from '~/types'

export type ReservationsState = {
  loading: boolean
  loaded: boolean
  data: Reservation[]
}

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (
  state: ReservationsState = initialState,
  action: AppActions,
) {
  switch (action.type) {
    case LOAD_RESERVATIONS_START: {
      return { ...state, loading: true }
    }

    case LOAD_RESERVATIONS_SUCCESS: {
      return { ...state, data: action.data, loading: false, loaded: true }
    }

    case LOAD_RESERVATIONS_FAIL: {
      return { ...state, loading: false }
    }

    default: {
      return state
    }
  }
}
