import type { AppActions, WaiverStates } from '../actions/types'

export type ResortAuthorizationState = {
  status: WaiverStates
}

const DEFAULT_STATE: ResortAuthorizationState = {
  status: '',
}
export default function resortAuthorization(
  state: ResortAuthorizationState = DEFAULT_STATE,
  action: AppActions,
) {
  switch (action.type) {
    case 'UPDATE_RESORT_AUTORIZATION_STATUS':
      return { ...state, status: action.state }

    default:
      return state
  }
}
