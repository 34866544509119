import HTTPError from '~/utils/http-error'

import { authedFetch } from '../fetch'

const endpoint = '/api/v2/resort-favorites'

export async function getResortFavorites() {
  const res = await authedFetch(endpoint)

  if (res.ok) {
    const json = await res.json()
    return json
  }

  throw new HTTPError(`failed: GET ${endpoint}`, res, await res.text())
}

export async function putResortFavorites(resortIDs: number[]) {
  const body = JSON.stringify({
    data: {
      resort_ids: resortIDs,
      ordered: false,
    },
  })

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body,
  }

  const res = await authedFetch(endpoint, options)

  if (res.ok) {
    const json = await res.json()
    return json
  }

  throw new HTTPError(`failed: PUT ${endpoint}`, res, await res.text())
}
