import { getUnixTime } from 'date-fns'

import {
  ALERT_TERMS,
  CHECK_TERMS,
  RESET_TERMS,
} from '../actions/terms-and-conditions'

import type { AppActions } from '../actions/types'

export type TermsState = {
  acceptedAt: number | null | undefined
  alert: boolean
}

const initialState = {
  acceptedAt: null,
  alert: false,
}

export default function termsAndConditions(
  state: TermsState = initialState,
  action: AppActions,
): TermsState {
  switch (action.type) {
    case CHECK_TERMS:
      return state.acceptedAt
        ? { ...state, acceptedAt: null }
        : { ...state, acceptedAt: getUnixTime(Date.now()), alert: false }

    case ALERT_TERMS:
      return { ...state, alert: true }

    case RESET_TERMS:
      return { ...initialState }

    default:
      return state
  }
}
