import { authedFetch } from '../fetch'

const PASSWORD_RESET_PATH = '/api/v2/password-resets'

export function requestSendPasswordReset(email: string) {
  const options = {
    method: 'post',
    body: JSON.stringify({
      email: email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return authedFetch(PASSWORD_RESET_PATH, options)
}

export function requestResetPassword(token: string, password: string) {
  const options = {
    method: 'PATCH',
    body: JSON.stringify({
      password: password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return authedFetch(`${PASSWORD_RESET_PATH}/${token}`, options)
}
