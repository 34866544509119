import React, { Component } from 'react'

import Modal from '~/components/modal'
import { i18n } from '~/i18n'

import ConfirmInviteStep from './partials/confirm-invite-step'
import EnterEmailStep from './partials/enter-email-step'

import type { GroupMember } from '~/types'

import './invite-participant-modal.scss'

export const Step = {
  ENTER_EMAIL: 1,
  CONFIRM_INVITE: 2,
}

export type Props = {
  invitee: GroupMember
  invitedByProfileId: string
  onClose: () => void
  onConfirm: (
    email: string,
    inviteeProfileId: string,
    invitedByProfileId: string,
  ) => void
  loading?: boolean
  errors?: Record<string, any>
  resetErrors: () => void
  currentStep: number
  goToStep: (step: number) => void
}

export type State = {
  email: string
}

export default class InviteParticipantModal extends Component<Props, State> {
  state = {
    email: this.props.invitee.email,
  }

  onChangeEmail = (email: string) => {
    this.props.resetErrors()
    this.setState({
      email,
    })
  }

  goToEnterEmailStep = () => this.props.goToStep(Step.ENTER_EMAIL)
  goToConfirmInviteStep = () => this.props.goToStep(Step.CONFIRM_INVITE)

  proceed = () => {
    const currentStep = this.props.currentStep

    if (currentStep == Step.ENTER_EMAIL) {
      this.goToConfirmInviteStep()
    } else if (currentStep === Step.CONFIRM_INVITE) {
      this.props.onConfirm(
        this.state.email,
        this.props.invitee.id,
        this.props.invitedByProfileId,
      )
    }
  }

  render() {
    return (
      <Modal
        className="amp-invite-participant-modal"
        onClose={this.props.onClose}
        title={i18n.t('components.invite_participant_modal.title')}
        loading={this.props.loading}
      >
        <EnterEmailStep
          invitee={this.props.invitee}
          email={this.state.email}
          currentStep={this.props.currentStep}
          onChangeEmail={this.onChangeEmail}
          onEdit={this.goToEnterEmailStep}
          onProceed={this.proceed}
          errors={this.props.errors || {}}
        />
        <ConfirmInviteStep
          invitee={this.props.invitee}
          currentStep={this.props.currentStep}
          onProceed={this.proceed}
        />
      </Modal>
    )
  }
}
