import { camelizeKeys } from 'humps'

import { requestLoadMyOrders } from '~/utils/api/orders'
import { reportGroupedError } from '~/utils/logger'

import type { ListOrder } from './order-list.types'

export type OrdersAction =
  | {
      type: 'LOAD_MY_ORDERS_REQUEST'
    }
  | {
      type: 'LOAD_MY_ORDERS_SUCCESS'
      orders: ListOrder[]
    }

export const LOAD_MY_ORDERS_REQUEST = 'LOAD_MY_ORDERS_REQUEST'
export const LOAD_MY_ORDERS_SUCCESS = 'LOAD_MY_ORDERS_SUCCESS'

function loadMyOrdersSuccess(orders: ListOrder[]) {
  return {
    type: LOAD_MY_ORDERS_SUCCESS,
    orders,
  }
}

export function loadMyOrders() {
  return async (dispatch: (...args: Array<any>) => any) => {
    try {
      dispatch({
        type: LOAD_MY_ORDERS_REQUEST,
      })
      const { data } = await requestLoadMyOrders()
      const orders = camelizeKeys(data)
      dispatch(loadMyOrdersSuccess(orders))
    } catch (error) {
      reportGroupedError('loadMyOrders', error)
    }
  }
}
