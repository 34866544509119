import HTTPError from '~/utils/http-error'

import { authedFetch } from '../fetch'

const PATH = '/api/v2/my-orders'

export async function requestLoadMyOrders() {
  const response = await authedFetch(PATH)

  if (response.ok) {
    const json = await response.json()
    return json
  }

  throw new HTTPError(`failed: GET ${PATH}`, response, await response.text())
}
