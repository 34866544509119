import {
  LOAD_NOTIFICATIONS_START,
  LOAD_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS_FAIL,
  DISMISS_NOTIFICATION,
} from '~/actions/notifications'

import type { AppActions } from '~/actions/types'
import type { Notification } from '~/types'

export type NotificationsState = {
  loading: boolean
  loaded: boolean
  data: Notification[]
}

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (
  state: NotificationsState = initialState,
  action: AppActions,
): NotificationsState {
  switch (action.type) {
    case LOAD_NOTIFICATIONS_START: {
      return { ...state, loading: true }
    }

    case LOAD_NOTIFICATIONS_SUCCESS: {
      return { ...state, data: action.data, loading: false, loaded: true }
    }

    case LOAD_NOTIFICATIONS_FAIL: {
      return { ...state, loading: false }
    }

    case DISMISS_NOTIFICATION: {
      return {
        ...state,
        data: state.data.filter(
          (notification: Notification) => notification.id !== action.data.id,
        ),
      }
    }

    default: {
      return state
    }
  }
}
