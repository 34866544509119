import { RESORT_CHARGE_LOADED } from '../actions/resort-charge'

import type { AppActions } from '../actions/types'
import type { ResortChargeState } from '~/types'

const DEFAULT_STATE: ResortChargeState = {
  loaded: false,
  data: {},
}

export default function resortCharge(
  state: ResortChargeState = DEFAULT_STATE,
  action: AppActions,
) {
  switch (action.type) {
    case RESORT_CHARGE_LOADED:
      return {
        data: action.data,
        loaded: true,
      }

    default:
      return state
  }
}
