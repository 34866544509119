import {
  GET_WAIVERS_REQUEST,
  GET_WAIVERS_SUCCESS,
  GET_WAIVERS_FAILURE,
} from '../actions/waivers'

import type { AppActions } from '../actions/types'
import type { Waiver } from '~/types'

export type State = {
  loading: boolean
  loaded: boolean
  data: Waiver[]
}

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (state: State = initialState, action: AppActions) {
  switch (action.type) {
    case GET_WAIVERS_REQUEST:
      return { ...state, loading: true }

    case GET_WAIVERS_SUCCESS:
      return { ...state, loading: false, data: action.waivers, loaded: true }

    case GET_WAIVERS_FAILURE:
      return { ...state, loading: false }

    default:
      return state
  }
}
