/**
 * Reducers convert actions into mutations of the application state.
 *
 * They should not have any side effects.
 */

import { combineReducers } from 'redux'

import myOrders from '~/components/order-list/order-list.reducer'
import flashMessages from '~/reducers/flash-messages'

import activeItem from './active-item'
import address from './address'
import application from './application'
import auth from './auth'
import cart from './cart'
import creditCardOnFile from './credit-card-on-file'
import credits from './credits'
import emailConfirmation from './email-confirmation'
import favoriteDestinations from './favorite-destinations'
import featureFlags from './feature-flags'
import groupMembers from './group-members'
import inviteParticipant from './invite-participant'
import minorConsent from './minor-consent'
import mountainChecklist from './mountain-checklist'
import navigation from './navigation'
import notifications from './notifications'
import onboarding from './onboarding'
import passwordReset from './password-reset'
import photoUpload from './photo-upload'
import productCatalog from './product-catalog'
import profile from './profile'
import profileDetailsEdit from './profile-details-edit'
import profileProducts from './profile-products'
import redeemableVouchers from './redeemable-vouchers'
import redemptions from './redemptions'
import reservations from './reservations'
import resortAuthorization from './resort-authorization'
import resortCharge from './resort-charge'
import resortFavorites from './resort-favorites'
import resorts from './resorts'
import resources from './resources'
import session from './session'
import termsAndConditions from './terms-and-conditions'
import ui from './ui'
import waivers from './waivers'

import type { ApplicationState } from './application'
import type { State as AuthState } from './auth'
import type { CreditCardOnFileState } from './credit-card-on-file'
import type { CreditsState } from './credits'
import type { EmailConfirmationType } from './email-confirmation'
import type { State as FDState } from './favorite-destinations'
import type { FeatureFlags } from './feature-flags'
import type { GroupMemberState } from './group-members'
import type { InviteParticipantState } from './invite-participant'
import type { MinorConsentState } from './minor-consent'
import type { MountainChecklistState } from './mountain-checklist'
import type { NavigationState } from './navigation'
import type { NotificationsState } from './notifications'
import type { PasswordResetState } from './password-reset'
import type { State as ProductCatalogState } from './product-catalog'
import type { ProfileProductState } from './profile-products'
import type { RedeemableVouchersState } from './redeemable-vouchers'
import type { RedemptionsState } from './redemptions'
import type { ReservationsState } from './reservations'
import type { ResortAuthorizationState } from './resort-authorization'
import type { ResortsState } from './resorts'
import type { TermsState } from './terms-and-conditions'
import type { UIState } from './ui'
import type { State as WaiversState } from './waivers'
import type { OrdersState } from '~/components/order-list/order-list.reducer'
import type {
  CartState,
  FlashMessage,
  GuestProfile,
  ResortFavorites,
  ProfileDetailsEdit,
  PhotoUploadState,
  ResortChargeState,
} from '~/types'

const products = (state = []) => state

export default () =>
  combineReducers<any>({
    address,
    application,
    activeItem,
    auth,
    cart,
    creditCardOnFile,
    credits,
    emailConfirmation,
    favoriteDestinations,
    featureFlags,
    flashMessages,
    groupMembers,
    minorConsent,
    mountainChecklist,
    myOrders,
    navigation,
    notifications,
    onboarding,
    passwordReset,
    photoUpload,
    productCatalog,
    products,
    profile,
    profileDetailsEdit,
    profileProducts,
    redemptions,
    redeemableVouchers,
    reservations,
    resortAuthorization,
    resortCharge,
    resortFavorites,
    resorts,
    resources,
    session,
    termsAndConditions,
    ui,
    inviteParticipant,
    waivers,
  })

export type AppState = {
  address: {
    updating: boolean
  }
  application: ApplicationState
  auth: AuthState
  cart: CartState
  creditCardOnFile: CreditCardOnFileState
  credits: CreditsState
  emailConfirmation: EmailConfirmationType
  favoriteDestinations: FDState
  featureFlags: FeatureFlags
  flashMessages: FlashMessage[]
  groupMembers: GroupMemberState
  inviteParticipant: InviteParticipantState
  minorConsent: MinorConsentState
  mountainChecklist: MountainChecklistState
  myOrders: OrdersState
  navigation: NavigationState
  notifications: NotificationsState
  passLookup: {
    associatingMedia: boolean
  }
  passwordReset: PasswordResetState
  photoUpload: PhotoUploadState
  productCatalog: ProductCatalogState
  profile: GuestProfile
  profileDetailsEdit: ProfileDetailsEdit
  profileProducts: ProfileProductState
  redemptions: RedemptionsState
  redeemableVouchers: RedeemableVouchersState
  reservations: ReservationsState
  resortAuthorization: ResortAuthorizationState
  resortCharge: ResortChargeState
  resortFavorites: ResortFavorites
  resorts: ResortsState
  resources: Record<string, Record<string, any>>
  termsAndConditions: TermsState
  ui: UIState
  waivers: WaiversState
}

export type { GroupMemberState } from './group-members'
export type { ProfileProductState } from './profile-products'
export type { State as ProductCatalogState } from './product-catalog'
