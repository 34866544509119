/**
 * Reducer for state related to the user's orders.
 */
import {
  REQUEST_PASSWORD_RESET,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_RESET_REQUEST_FAILED,
  PASSWORD_RESET_REQUEST_FAILED_EMAIL_NOT_FOUND,
  PASSWORD_RESET,
  PASSWORD_RESET_FAILED,
} from '../actions/password-reset'

import type { AppActions } from '../actions/types'

const DEFAULT_STATE = {
  requestSuccessful: undefined,
  successful: undefined,
  loading: false,
}

export type PasswordResetState = {
  loading: boolean
  requestSuccessful?: boolean
  successful?: boolean
}

export default function (
  state: PasswordResetState = DEFAULT_STATE,
  action: AppActions,
) {
  switch (action.type) {
    case REQUEST_PASSWORD_RESET:
      return { ...state, loading: true }

    case PASSWORD_RESET_REQUESTED:
      return { ...state, requestSuccessful: true, loading: false }

    case PASSWORD_RESET_REQUEST_FAILED:
      return { ...state, requestSuccessful: false, loading: false }

    case PASSWORD_RESET_REQUEST_FAILED_EMAIL_NOT_FOUND:
      return {
        ...state,
        requestSuccessful: false,
        emailNotFound: true,
        loading: false,
      }

    case PASSWORD_RESET:
      return { ...state, successful: true, loading: false }

    case PASSWORD_RESET_FAILED:
      return { ...state, successful: false, loading: false }
  }

  return state
}
