/**
 * Reducer for state related to the user's orders.
 */
import {
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  RESEND_EMAIL_SUCCESS,
} from '../actions/email-confirmation'

const DEFAULT_STATE = {
  confirmEmailSuccess: undefined,
  resendEmailSuccess: undefined,
}

export type EmailConfirmationType = {
  confirmEmailSuccess: boolean | null | undefined
  resendEmailSuccess: boolean | null | undefined
}

export default function (
  state: EmailConfirmationType = DEFAULT_STATE,
  action: {
    type: string
  },
) {
  switch (action.type) {
    case RESEND_EMAIL_SUCCESS:
      return { ...state, resendEmailSuccess: true }

    case CONFIRM_EMAIL_SUCCESS:
      return { ...state, confirmEmailSuccess: true }

    case CONFIRM_EMAIL_FAILURE:
      return { ...state, confirmEmailSuccess: false }
  }

  return state
}
