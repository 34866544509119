import React, { PureComponent } from 'react'

import Form from '~/components//form'
import { i18n } from '~/i18n'

import type { InputValue } from '~/components/form/form.types'

type EmailFormProps = {
  email: string
  onChange: (email: string) => void
  errors: Record<string, any>
}

export default class EmailForm extends PureComponent<EmailFormProps> {
  onChange = (key: string, value: InputValue) => {
    if (key === 'email' && typeof value === 'string') {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <Form
        object={{
          email: this.props.email,
        }}
        onChange={this.onChange}
        errors={this.props.errors}
        fields={{
          email: {
            type: 'email',
            label: i18n.t(
              'components.invite_participant_modal.step_1.email_label',
            ),
          },
        }}
      />
    )
  }
}
