import {
  LOAD_REDEEMABLE_VOUCHERS_START,
  LOAD_REDEEMABLE_VOUCHERS_SUCCESS,
  LOAD_REDEEMABLE_VOUCHERS_FAIL,
} from '~/actions/redeemable-vouchers'

import type { AppActions } from '~/actions/types'
import type { RedeemableVoucher } from '~/types'

export type RedeemableVouchersState = {
  loading: boolean
  loaded: boolean
  data: RedeemableVoucher[]
}

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (
  state: RedeemableVouchersState = initialState,
  action: AppActions,
) {
  switch (action.type) {
    case LOAD_REDEEMABLE_VOUCHERS_START: {
      return { ...state, loading: true }
    }

    case LOAD_REDEEMABLE_VOUCHERS_SUCCESS: {
      return { ...state, data: action.data, loading: false, loaded: true }
    }

    case LOAD_REDEEMABLE_VOUCHERS_FAIL: {
      return { ...state, loading: false }
    }

    default: {
      return state
    }
  }
}
