import {
  ONBOARDING_LOADED,
  UPDATE_ONBOARDING_STATUS,
  ONBOARDING_RESET,
  onboardingStatus,
} from '~/actions/onboarding'

import type { OnboardingActions } from '~/actions/onboarding'
import type { Onboarding, ValueOf } from '~/types'

type OnboardingState = {
  status: ValueOf<typeof onboardingStatus> | null
  data: Onboarding | object
}

const DEFAULT_STATE = {
  status: null,
  data: {},
}

export default function onboarding(
  state: OnboardingState = DEFAULT_STATE,
  action: OnboardingActions,
) {
  switch (action.type) {
    case ONBOARDING_RESET:
      return DEFAULT_STATE

    case ONBOARDING_LOADED:
      return { ...state, status: onboardingStatus.LOADED, data: action.data }

    case UPDATE_ONBOARDING_STATUS:
      return { ...state, status: action.status }

    default:
      return state
  }
}
