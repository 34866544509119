import HTTPError from '~/utils/http-error'

import { authedFetch } from '../fetch'

const PATH = '/api/v2/invite'
export async function postInvite(
  profileId: string,
  email: string,
): Promise<void> {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      data: {
        profile_id: profileId,
        email: email,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }

  const response = await authedFetch(PATH, options)

  if (response.ok) return

  throw new HTTPError(`failed: GET ${PATH}`, response, await response.text())
}
