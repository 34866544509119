/**
 * Reducer for state related to authentication.
 */
import {
  AUTH_CHECKED,
  AUTHENTICATED,
  AUTHENTICATING,
  AUTHENTICATION_FAILED,
  CHECKING_AUTH,
  RESET_AUTH_REJECTED,
} from '../actions/auth'

export type State = {
  authenticated: boolean
  authenticationAttemptRejected: boolean
  errorMessage: string
  pending: boolean
}

// This is the initial state that will be stored in the global state tree.
const initialAuthState = {
  authenticated: false,
  authenticationAttemptRejected: false,
  errorMessage: '',
  pending: false,
}
/**
 * Given the existing state and an action this computes the new state.
 *
 * @param  {object} state  The current state of the auth section of global
 *                         state tree. Defaults to a initialized object.
 * @param  {object} action The action to use to compute the new state.
 * @return {object}        The new state.
 */

export default function auth(
  state: State = initialAuthState,
  action: (...args: Array<any>) => any,
): State {
  // @ts-expect-error TS2339
  switch (action.type) {
    case CHECKING_AUTH:
    case AUTHENTICATING:
      return Object.assign({}, state, {
        pending: true,
        authenticationAttemptRejected: false,
      })

    case AUTHENTICATION_FAILED:
      return Object.assign({}, state, {
        authenticated: false,
        authenticationAttemptRejected: true,
        // @ts-expect-error TS2339
        errorMessage: action.reason,
        pending: false,
      })

    case AUTHENTICATED:
      return Object.assign({}, state, {
        authenticated: true,
        pending: false,
      })

    case AUTH_CHECKED:
      return Object.assign({}, state, {
        // @ts-expect-error TS2339
        authenticated: action.loggedIn,
        pending: false,
      })

    case RESET_AUTH_REJECTED:
      return Object.assign({}, state, {
        authenticated: false,
        authenticationAttemptRejected: false,
        errorMessage: '',
        pending: false,
      })

    default:
      return state
  }
}
