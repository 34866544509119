import { MOUNTAIN_CHECKLIST_LOADED } from '../actions/mountain-checklist'

import type { MountainChecklist } from '~/types'

export type MountainChecklistState = {
  loaded: boolean
  data: MountainChecklist
}

export const DEFAULT_STATE = {
  loaded: false,
  data: {
    uploadPhoto: [],
    waiversRequired: [],
  },
}

export default function mountainChecklist(
  state: MountainChecklistState = DEFAULT_STATE,
  action: Record<string, any>,
) {
  switch (action.type) {
    case MOUNTAIN_CHECKLIST_LOADED:
      return { ...state, loaded: true, data: action.data }

    default:
      return state
  }
}
