import type { Onboarding } from '~/types'

const editableFieldTranslation = {
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  dob: 'dob',
}

export default function onboardingDeserializer(
  data: Record<string, any>,
  token: string,
): Onboarding {
  if (Object.keys(data).includes('start_of_last_name')) {
    return {
      firstName: data.first_name,
      startOfLastName: data.start_of_last_name,
      type: 'basic',
      token,
    }
  } else {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      dob: data.dob,
      email: data.email,
      // @ts-expect-error TS2322
      editableFields: data.editable_fields.map(
        (attribute) => editableFieldTranslation[attribute],
      ),
      type: 'detailed',
      token,
    }
  }
}
