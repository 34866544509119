/**
 * Defines actions and action creators related to resources.
 *
 * This is a generic way of getting data from the server without needing to
 * create actions and related reducers for every resource.
 */

import { camelizeKeys } from 'humps'

import HTTPError from '~/utils/http-error'

import { authedFetch } from '../utils/fetch'
import { reportGroupedError } from '../utils/logger'

import type { AppState } from '../reducers'

type Dispatch = (...args: Array<any>) => any
type GetState = () => AppState

type Resource = {
  loading: boolean
  loaded: boolean
  data: Record<string, any> | null
  errorCode: string | null
} //
// - Actions and Sync Action Creators
//

export const RESOURCE_UPDATE = 'RESOURCE_UPDATE'
export function resourceUpdate(path: string, resource: Resource) {
  return {
    type: RESOURCE_UPDATE,
    path,
    resource,
  }
}

function resourceLoading(path) {
  return resourceUpdate(path, {
    loading: true,
    loaded: false,
    data: null,
    errorCode: null,
  })
}

function resourceLoaded(path, data) {
  return resourceUpdate(path, {
    loading: false,
    loaded: true,
    data,
    errorCode: null,
  })
}

function resourceLoadFailed(path, errorCode) {
  return resourceUpdate(path, {
    loading: false,
    loaded: false,
    data: null,
    errorCode,
  })
}

//
// - Async Action Creators
//
export function loadResource(name: string, id?: string) {
  let resourcePath = `/api/v2/${name}`

  if (id) {
    resourcePath += `/${id}`
  }

  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState()
    const resource = state.resources[resourcePath]

    if (resource && resource.loading) {
      return
    }

    dispatch(resourceLoading(resourcePath))
    return authedFetch(resourcePath)
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        } else {
          return Promise.reject(
            new HTTPError(`Unable to load resource ${resourcePath}`, resp, ''),
          )
        }
      })
      .then((body) => {
        dispatch(resourceLoaded(resourcePath, camelizeKeys(body.data)))
      })
      .catch((err) => {
        reportGroupedError('loadResource', err)
        dispatch(resourceLoadFailed(resourcePath, 'UNKNOWN'))
      })
  }
}
