import { NAVIGATION_LOCATION_CHANGE_ACTION_TYPE } from '~/hooks/use-location-change-handler'

import type { Middleware } from 'redux'

const sanitizePathname = (pathname) => pathname.match(/^[#/]?(.*)/)[1]

const adobeTriggerView = () => {
  document.addEventListener('at-library-loaded', function () {
    window.adobe.target.triggerView(sanitizePathname(window.location.pathname))
  })

  const middleware: Middleware<any, any> = () => (next) => (action: any) => {
    const triggerView = window.adobe?.target?.triggerView
    const returnValue = next(action)

    if (
      window.adobe?.target?.triggerView &&
      action.type === NAVIGATION_LOCATION_CHANGE_ACTION_TYPE
    ) {
      triggerView(sanitizePathname(action.payload.location.pathname))
    }

    return returnValue
  }

  return middleware
}

export default adobeTriggerView
