import { DESKTOP_WIDTH } from '~/config'

import type { ApplicationAction } from '~/actions/application'

export type ApplicationState = {
  width: number
  isDesktop: boolean
}

const isDesktop = (width) => width >= DESKTOP_WIDTH

const initialState = (): ApplicationState => {
  const width = window.innerWidth
  return {
    width,
    isDesktop: isDesktop(width),
  }
}

export default (
  state: ApplicationState = initialState(),
  action: ApplicationAction,
) => {
  switch (action.type) {
    case 'SET_APPLICATION_WIDTH':
      return {
        ...state,
        width: action.payload,
        isDesktop: isDesktop(action.payload),
      }

    default:
      return state
  }
}
