import React, { Component, Fragment } from 'react'

import Alert from '~/components/alert'
import ContentBlock from '~/components/content-block'
import ResponsiveButton from '~/components/responsive-button'
import Step from '~/components/step'
import { i18n } from '~/i18n'
import { translateErrors } from '~/utils/error-messages'
import { isTeenProfile } from '~/utils/profile'
import {
  INVALID,
  REQUIRED,
  ALREADY_REGISTERED,
  validateRequired,
  validateEmail,
} from '~/utils/validations'

import EmailForm from '../email-form'

import type { GroupMember } from '~/types'

type Props = {
  invitee: GroupMember
  email: string
  onChangeEmail: (email: string) => void
  currentStep: number
  onProceed: () => void
  onEdit: () => void
  errors: Record<string, any>
}

type State = {
  validationErrors: Record<string, any>
}

export default class EnterEmailStep extends Component<Props, State> {
  state = {
    validationErrors: {},
  }

  get translatedErrorsByKey() {
    return {
      email: {
        [INVALID]: i18n.t('components.errors.email.invalid'),
        [REQUIRED]: i18n.t('components.errors.email.required'),
        [ALREADY_REGISTERED]: i18n.t(
          'components.invite_participant_modal.errors.email_address_already_taken',
        ),
      },
    }
  }

  onProceed = () => {
    const email = this.props.email
    const validationErrors = {
      ...validateRequired(
        {
          email,
        },
        'email',
      ),
      ...validateEmail({
        email,
      }),
    }

    this.setState({
      validationErrors,
    })

    const allErrors = { ...validationErrors, ...this.props.errors }

    if (Object.keys(allErrors).length === 0) {
      this.props.onProceed()
    }
  }

  render() {
    const { currentStep, invitee, email, onChangeEmail, onEdit } = this.props
    const errors = translateErrors(
      { ...this.state.validationErrors, ...this.props.errors },
      this.translatedErrorsByKey,
    )

    return (
      <Step
        step={1}
        currentStep={currentStep}
        title={i18n.t('components.invite_participant_modal.step_1.heading', {
          invitee_first_name: invitee.firstName,
        })}
        onEdit={onEdit}
        active={
          <Fragment>
            <ContentBlock>
              {i18n.t('components.invite_participant_modal.step_1.content_md', {
                invitee_full_name: invitee.firstName,
                invitee_first_name: `${invitee.firstName} ${invitee.lastName}`,
              })}
            </ContentBlock>
            <EmailForm email={email} onChange={onChangeEmail} errors={errors} />
            <div className="actions">
              <ResponsiveButton type="progress" onClick={this.onProceed}>
                {i18n.t('components.invite_participant_modal.step_1.next_btn')}
              </ResponsiveButton>
            </div>
            {isTeenProfile(invitee) && (
              <Alert
                heading={i18n.t(
                  'components.invite_participant_modal.restricted_account_alert.heading',
                )}
              >
                <ContentBlock>
                  {i18n.t(
                    'components.invite_participant_modal.restricted_account_alert.content_md',
                  )}
                </ContentBlock>
              </Alert>
            )}
          </Fragment>
        }
        inactive={email}
      />
    )
  }
}
