import { combineReducers } from 'redux'

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  GO_TO_STEP,
  INVITE_PARTICIPANT_STARTED,
  INVITE_PARTICIPANT_COMPLETED,
  INVITE_PARTICIPANT_FAILED,
  RESET_ERRORS,
} from '~/actions/invite-participant'
import { Step } from '~/components/invite-participant-modal'
import { ALREADY_REGISTERED, BASE_ERROR_KEY } from '~/utils/validations'

import type { AppActions } from '../actions/types'

export type InviteParticipantState = {
  loading: boolean
  modalOpen: boolean
  errors: Record<string, any>
  currentStep: number
}

function loading(state = false, action: AppActions): boolean {
  switch (action.type) {
    case INVITE_PARTICIPANT_STARTED:
      return true

    case INVITE_PARTICIPANT_COMPLETED:
    case INVITE_PARTICIPANT_FAILED:
      return false

    default:
      return state
  }
}

function errors(
  state: Record<string, any> = {},
  action: AppActions,
): Record<string, any> {
  switch (action.type) {
    case RESET_ERRORS:
    case INVITE_PARTICIPANT_COMPLETED:
      return {}

    case INVITE_PARTICIPANT_FAILED:
      if (action.errorCode === ALREADY_REGISTERED) {
        return {
          email: action.errorCode,
        }
      } else {
        return {
          [BASE_ERROR_KEY]: action.errorCode,
        }
      }

    default:
      return state
  }
}

function modalOpen(state = false, action: AppActions): boolean {
  switch (action.type) {
    case OPEN_MODAL:
      return true

    case INVITE_PARTICIPANT_COMPLETED:
    case CLOSE_MODAL:
      return false

    default:
      return state
  }
}

function currentStep(
  state: number = Step.ENTER_EMAIL,
  action: AppActions,
): number {
  switch (action.type) {
    case GO_TO_STEP:
      return action.step

    case INVITE_PARTICIPANT_COMPLETED:
    case INVITE_PARTICIPANT_FAILED:
      return Step.ENTER_EMAIL

    default:
      return state
  }
}

export default combineReducers<any>({
  loading,
  errors,
  modalOpen,
  currentStep,
})
