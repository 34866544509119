/**
 * Defines actions and action creators related to the active item internal id.
 */
//
// - Actions and Sync Action Creators
//
import type { ActiveItem } from '~/types'

export type ActiveItemActions =
  | {
      type: 'SET_ACTIVE_ITEM'
      data: ActiveItem
    }
  | {
      type: 'REMOVE_ACTIVE_ITEM'
    }

export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM'
export function setActiveItem(data: ActiveItem) {
  return {
    type: SET_ACTIVE_ITEM,
    data,
  }
}

export const REMOVE_ACTIVE_ITEM = 'REMOVE_ACTIVE_ITEM'
export function removeActiveItem() {
  return {
    type: REMOVE_ACTIVE_ITEM,
  }
}
