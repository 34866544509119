import {
  LOAD_MY_ORDERS_REQUEST,
  LOAD_MY_ORDERS_SUCCESS,
} from './order-list.actions'

import type { ListOrder } from './order-list.types'
import type { AppActions } from '~/actions/types'

export type OrdersState = {
  loading: boolean
  entities: ListOrder[]
}

const initialState = {
  loading: false,
  entities: [],
}

export default function myOrders(
  state: OrdersState = initialState,
  action: AppActions,
): OrdersState {
  switch (action.type) {
    case LOAD_MY_ORDERS_REQUEST:
      return { ...state, loading: true }

    case LOAD_MY_ORDERS_SUCCESS: {
      const sortedOrders = action.orders.sort(
        // @ts-expect-error TS2362
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      )
      return { ...state, loading: false, entities: sortedOrders }
    }

    default:
      return state
  }
}
