import { NAVIGATION_LOCATION_CHANGE_ACTION_TYPE } from '~/hooks/use-location-change-handler'

import { extendSessionInBackground } from '../actions/session'

import type { Middleware } from 'redux'

/**
 * Middleware to keep the session alive when the user is logged in.
 */
const keepAlive: Middleware<any, any> = (store) => (next) => (action: any) => {
  const returnValue = next(action)

  if (action.type === NAVIGATION_LOCATION_CHANGE_ACTION_TYPE) {
    // @ts-expect-error TS2345
    store.dispatch(extendSessionInBackground())
  }

  return returnValue
}

export default keepAlive
