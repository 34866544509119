type SetApplicationWidthAction = {
  type: 'SET_APPLICATION_WIDTH'
  payload: number
}

export type ApplicationAction = SetApplicationWidthAction

export const setApplicationWidth = (
  width: number,
): SetApplicationWidthAction => ({
  type: 'SET_APPLICATION_WIDTH',
  payload: width,
})
