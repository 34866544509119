import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_SELECTED,
} from '../actions/favorite-destinations'

import type { AppActions } from '../actions/types'

export type SelectedFavorites = Record<number, boolean>

export type State = {
  modalOpen: boolean
  selectedFavorites: SelectedFavorites | null | undefined
}

const initialState = {
  modalOpen: false,
  selectedFavorites: null,
}

const favoriteDestinations = (
  state: State = initialState,
  action: AppActions,
): State => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        modalOpen: true,
        selectedFavorites: null,
      }

    case CLOSE_MODAL:
      return { ...state, modalOpen: false }

    case SET_SELECTED:
      return { ...state, selectedFavorites: action.selectedFavorites }

    default:
      return state
  }
}

export default favoriteDestinations
