import { camelizeKeys } from 'humps'

import getCustomerAPI from '~/utils/api/get-customer-api'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import type { Notification } from '~/types'

export const LOAD_NOTIFICATIONS_START = 'notifications/LOAD_START'
export const LOAD_NOTIFICATIONS_SUCCESS = 'notifications/LOAD_SUCCESS'
export const LOAD_NOTIFICATIONS_FAIL = 'notifications/LOAD_FAIL'
export const DISMISS_NOTIFICATION = 'notifications/DISMISS'
export type NotificationActions =
  | {
      type: 'notifications/LOAD_START'
    }
  | {
      type: 'notifications/LOAD_SUCCESS'
      data: Notification[]
    }
  | {
      type: 'notifications/LOAD_FAIL'
    }
  | {
      type: 'notifications/DISMISS'
      data: Notification
    }

export function loadNotifications({
  reload,
}: {
  reload?: boolean
} = {}) {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const {
      notifications: { loaded, loading },
    } = getState()

    if (loading || (loaded && !reload)) return

    dispatch({
      type: LOAD_NOTIFICATIONS_START,
    })

    try {
      const api = getCustomerAPI()
      const response = await api.getNotifications()

      if (response.ok) {
        const { data } = await response.json()
        dispatch({
          type: LOAD_NOTIFICATIONS_SUCCESS,
          data: data.map((notification) => camelizeKeys(notification)),
        })
      } else {
        throw new HTTPError('failed: GET', response, await response.text())
      }
    } catch (error) {
      reportGroupedError('loadNotifications', error)
      dispatch({
        type: LOAD_NOTIFICATIONS_FAIL,
      })
    }
  }
}

export function dismissNotification(notification: Notification) {
  return async (dispatch: (...args: Array<any>) => any) => {
    dispatch({
      type: DISMISS_NOTIFICATION,
      data: notification,
    })

    try {
      const api = getCustomerAPI()
      const response = await api.dismissNotification(notification.id)

      if (!response.ok) {
        throw new HTTPError(
          `failed: dismissNotification`,
          response,
          await response.text(),
        )
      }
    } catch (error) {
      reportGroupedError('dismissNotification', error, {
        message: 'Dismiss Notification Failed',
      })
    }
  }
}
