import { ageNow } from '~/utils/date'
import { MIN_ADULT, MIN_TEEN } from '~/utils/validations'

import type { GroupMember } from '~/types'

export const isTeenProfile = (profile: GroupMember): boolean => {
  const age = ageNow(profile.dob)
  return age >= MIN_TEEN && age < MIN_ADULT
}

export const isAdultProfile = (profile: GroupMember): boolean =>
  ageNow(profile.dob) >= MIN_ADULT
