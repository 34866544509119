import React from 'react'

import Icon from '~/components/icon'

import type { Props } from './alert.types'

import './alert.scss'

export default function Alert({ children, heading }: Props) {
  return (
    <div className="amp-alert">
      <Icon glyph="alert" />
      <div className="container">
        <h3>{heading}</h3>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}
