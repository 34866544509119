import React, { Fragment } from 'react'

import ContentBlock from '~/components/content-block'
import ResponsiveButton from '~/components/responsive-button'
import Step from '~/components/step'
import { i18n } from '~/i18n'

import type { GroupMember } from '~/types'

type ConfirmInviteStepProps = {
  invitee: GroupMember
  currentStep: number
  onProceed: () => void
}

export default function ConfirmInviteStep({
  invitee,
  currentStep,
  onProceed,
}: ConfirmInviteStepProps) {
  return (
    <Step
      step={2}
      currentStep={currentStep}
      title={i18n.t('components.invite_participant_modal.step_2.heading')}
      active={
        <Fragment>
          <ContentBlock>
            {i18n.t('components.invite_participant_modal.step_2.content_md', {
              invitee_first_name: invitee.firstName,
            })}
          </ContentBlock>
          <div className="actions">
            <ResponsiveButton type="progress" onClick={onProceed}>
              {i18n.t('components.invite_participant_modal.step_2.confirm_btn')}
            </ResponsiveButton>
          </div>
        </Fragment>
      }
    />
  )
}
