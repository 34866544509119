/**
 * Defines actions and action creators related to resetting a users password.
 */
import delay from '~/utils/delay'
import HTTPError from '~/utils/http-error'

import { authenticated } from './auth'
import {
  requestSendPasswordReset,
  requestResetPassword,
} from '../utils/api/password-reset'
import { reportGroupedError } from '../utils/logger'

type Dispatch = (...args: Array<any>) => any

export type PasswordResetActions =
  | {
      type: 'REQUEST_PASSWORD_RESET'
    }
  | {
      type: 'PASSWORD_RESET_REQUESTED'
    }
  | {
      type: 'PASSWORD_RESET_REQUEST_FAILED'
    }
  | {
      type: 'PASSWORD_RESET_REQUEST_FAILED_EMAIL_NOT_FOUND'
    }
  | {
      type: 'PASSWORD_RESET'
    }
  | {
      type: 'PASSWORD_RESET_FAILED'
    }

//
// - Actions and Sync Action Creators
//
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export function requestPasswordReset() {
  return {
    type: REQUEST_PASSWORD_RESET,
  }
}

export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED'
export function passwordResetRequested() {
  return {
    type: PASSWORD_RESET_REQUESTED,
  }
}

export const PASSWORD_RESET_REQUEST_FAILED = 'PASSWORD_RESET_REQUEST_FAILED'
export function passwordResetRequestFailed() {
  return {
    type: PASSWORD_RESET_REQUEST_FAILED,
  }
}

export const PASSWORD_RESET_REQUEST_FAILED_EMAIL_NOT_FOUND =
  'PASSWORD_RESET_REQUEST_FAILED_EMAIL_NOT_FOUND'
export function passwordResetRequestFailedEmailNotFound() {
  return {
    type: PASSWORD_RESET_REQUEST_FAILED_EMAIL_NOT_FOUND,
  }
}

export const PASSWORD_RESET = 'PASSWORD_RESET'
export function passwordReset() {
  return {
    type: PASSWORD_RESET,
  }
}

export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED'
export function passwordResetFailed() {
  return {
    type: PASSWORD_RESET_FAILED,
  }
}

//
// - Async Action Creators
//
export function forgotPassword(email: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestPasswordReset())

    try {
      const minDelay = delay()

      const resp = await requestSendPasswordReset(email)
      await minDelay

      if (resp.ok) {
        dispatch(passwordResetRequested())
      } else if (resp.status === 422) {
        dispatch(passwordResetRequestFailedEmailNotFound())
      } else {
        const text = await resp.text()
        throw new HTTPError('Unable to send password reset', resp, text)
      }
    } catch (err) {
      reportGroupedError('forgotPassword', err)
      dispatch(passwordResetRequestFailed())
    }
  }
}

export function resetPassword(token: string, newPassword: string) {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await requestResetPassword(token, newPassword)

      if (resp.ok) {
        dispatch(authenticated())
        dispatch(passwordReset())
      } else if (resp.status === 422) {
        dispatch(passwordResetFailed())
      } else {
        const text = await resp.text()
        throw new HTTPError('Unable to reset password', resp, text)
      }
    } catch (err) {
      reportGroupedError('resetPassword', err)
      dispatch(passwordResetFailed())
    }
  }
}
