import {
  LOAD_RESORT_FAVORITES_SUCCESS,
  UPDATE_RESORT_FAVORITES_SUCCESS,
  UPDATE_RESORT_FAVORITES_START,
  UPDATE_RESORT_FAVORITES_FAIL,
} from '../actions/resort-favorites'

import type { AppActions } from '../actions/types'
import type { ResortFavorites } from '~/types'

const DEFAULT_STATE: ResortFavorites = {
  updating: false,
  loaded: false,
  data: {},
}

export default function (
  state: ResortFavorites = DEFAULT_STATE,
  action: AppActions,
) {
  switch (action.type) {
    case UPDATE_RESORT_FAVORITES_SUCCESS:
      return { ...state, updating: false, loaded: true, data: action.data }

    case LOAD_RESORT_FAVORITES_SUCCESS:
      return { ...state, loaded: true, data: action.data }

    case UPDATE_RESORT_FAVORITES_START:
      return { ...state, updating: true }

    case UPDATE_RESORT_FAVORITES_FAIL:
      return { ...state, updating: false }

    default:
      return state
  }
}
