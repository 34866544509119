/**
 * Reducer for state related to the user's profile.
 */
import { CONFIRM_EMAIL_SUCCESS } from '../actions/email-confirmation'
import { PROFILE_UPDATE } from '../actions/profile'

// This is the initial state that will be stored in the global state tree.
export const initialProfileState = {
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  loaded: false,
  loadFailed: false,
  errors: {},
  password: null,
}

function updateProfile(state, profile) {
  if (state.id && profile.id && state.id !== profile.id) return state
  return {
    ...state,
    ...profile,
    cloudinary: { ...state.cloudinary, ...profile.cloudinary },
  }
}
/**
 * Given the existing state and an action this computes the new state.
 *
 * @param  {object} state  The current state of the auth section of global
 *                         state tree. Defaults to a initialized object.
 * @param  {object} action The action to use to compute the new state.
 * @return {object}        The new state.
 */

export default function profile(
  state: Record<string, any> = initialProfileState,
  action: Record<string, any>,
) {
  switch (action.type) {
    case PROFILE_UPDATE:
      return updateProfile(state, action.profile)

    case CONFIRM_EMAIL_SUCCESS:
      return { ...state, confirmedAt: new Date().toISOString() }

    default:
      return state
  }
}
