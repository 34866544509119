import { camelizeKeys } from 'humps'
import debounce from 'lodash/debounce'
import { applyMiddleware, compose, createStore } from 'redux'
import { thunk } from 'redux-thunk'

import { setApplicationWidth } from '~/actions/application'
import track, {
  enhancedCommerceMiddleware,
} from '~/utils/enhanced-commerce-tracking'

import adobeTriggerView from './middleware/adobe-trigger-view'
import keepAlive from './middleware/keep-alive'
import createReducers from './reducers'
import sessionCookie from './utils/session-cookie'

import type { CartState } from '~/types'

function create(initialState: Record<string, any>) {
  const reducers = createReducers()

  const middleware = [
    thunk,
    enhancedCommerceMiddleware,
    keepAlive,
    adobeTriggerView(),
  ]

  const enhancer = compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  )
  return createStore<any, any, any>(reducers, initialState, enhancer)
}

function getCart() {
  const { updatedAt, ...rest } = camelizeKeys(window.CONSTANTS.cart)

  return { ...rest }
}

function getAuth() {
  return {
    authenticationAttemptRejected: false,
    pending: false,
    authenticated: sessionCookie.isAuthenticated(),
  }
}

function getSession() {
  return {
    lastUpdated: Date.now(),
    updating: false,
  }
}

function getInitialState() {
  return {
    auth: getAuth(),
    cart: getCart(),
    profile: {
      loaded: false,
    },
    session: getSession(),
  }
}

export function createAndInitializeStore({
  trackCart = true,
}: {
  trackCart?: boolean
} = {}) {
  const initialState = getInitialState()

  if (trackCart) track.cart(initialState.cart as CartState)

  const store = create(initialState)

  store.dispatch(setApplicationWidth(window.innerWidth))

  window.onresize = debounce(() => {
    store.dispatch(setApplicationWidth(window.innerWidth))
  }, 200)

  return store
}
