import {
  LOAD_CREDIT_CARD_ON_FILE_START,
  LOAD_CREDIT_CARD_ON_FILE_SUCCESS,
  LOAD_CREDIT_CARD_ON_FILE_NO_CARD,
  LOAD_CREDIT_CARD_ON_FILE_FAIL,
} from '~/actions/credit-card-on-file'

import type { AppActions } from '~/actions/types'
import type { TokenizedCreditCard } from '~/types'

export type CreditCardOnFileState = {
  loading: boolean
  loaded: boolean
  cardExistsOnFile: boolean
  data: TokenizedCreditCard | null | undefined
}

const initialState = {
  loading: false,
  cardExistsOnFile: false,
  loaded: false,
  data: null,
}

export default function (
  state: CreditCardOnFileState = initialState,
  action: AppActions,
) {
  switch (action.type) {
    case LOAD_CREDIT_CARD_ON_FILE_START: {
      return { ...state, loading: true }
    }

    case LOAD_CREDIT_CARD_ON_FILE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        cardExistsOnFile: true,
        loading: false,
        loaded: true,
      }
    }

    case LOAD_CREDIT_CARD_ON_FILE_NO_CARD: {
      return { ...state, cardExistsOnFile: false, loading: false, loaded: true }
    }

    case LOAD_CREDIT_CARD_ON_FILE_FAIL: {
      return { ...state, loading: false }
    }

    default: {
      return state
    }
  }
}
