import { SET_ACTIVE_ITEM, REMOVE_ACTIVE_ITEM } from '../actions/active-item'

import type { ActiveItemActions } from '../actions/active-item'
import type { ActiveItem } from '~/types'

export const DEFAULT_STATE = {
  internalId: null,
  isEditing: false,
}

export default function (
  state: ActiveItem = DEFAULT_STATE,
  action: ActiveItemActions,
) {
  switch (action.type) {
    case SET_ACTIVE_ITEM:
      return action.data

    case REMOVE_ACTIVE_ITEM:
      return DEFAULT_STATE

    default:
      return state
  }
}
