import {
  UPDATE_ADDRESSES_START,
  UPDATE_ADDRESSES_SUCCESS,
  UPDATE_ADDRESSES_FAIL,
} from '../actions/address'

import type { AppActions } from '../actions/types'

type AddressStateType = {
  updating: boolean
}

const initialState = {
  updating: false,
}

export default function (
  state: AddressStateType = initialState,
  action: AppActions,
) {
  switch (action.type) {
    case UPDATE_ADDRESSES_START:
      return { ...state, updating: true }

    case UPDATE_ADDRESSES_SUCCESS:
      return { ...state, updating: false }

    case UPDATE_ADDRESSES_FAIL:
      return { ...state, updating: false }

    default:
      return state
  }
}
