/**
 * Defines actions and action creators related to email confirmation.
 */

import { addFlashMessage } from '~/actions/flash-message'
import { push } from '~/actions/navigation'
import { i18n } from '~/i18n'
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'

import { EMAIL_CONFIRMATION_STATUS } from '../config'
import { reportGroupedError } from '../utils/logger'

type Dispatch = (...args: Array<any>) => any //
// - Actions and Sync Action Creators
//

export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS'

function confirmEmailWarning() {
  return addFlashMessage(
    'info',
    i18n.t('pages.account_confirm.email_already_confirmed'),
  )
}

export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'

function confirmEmailSuccess() {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
  }
}

export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE'

function confirmEmailFailure() {
  return {
    type: CONFIRM_EMAIL_FAILURE,
  }
}

//
// - Async Action Creators
//

/**
 * resendConfirmationEmail requests the iaa server to resend a confirmation
 * email for the provided email.
 *
 * @return {Function}  Thunk which will initiate the request to the server.
 */
let requestingResend = false
export function resendConfirmationEmail(email: string) {
  return (dispatch: Dispatch) => {
    if (requestingResend) return
    requestingResend = true
    const opts = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        data: {
          type: 'confirmation',
          email,
        },
      }),
    }
    return authedFetch('/api/v2/emails', opts)
      .then((resp) => {
        requestingResend = false

        if (resp.status === 202) {
          dispatch(
            addFlashMessage(
              'info',
              i18n.t('pages.account_confirm.email_resent'),
              {
                navCount: 0,
              },
            ),
          )
        } else {
          return Promise.reject(new HTTPError('unknown error', resp, ''))
        }
      })
      .catch((error) => {
        requestingResend = false
        reportGroupedError('resendConfirmationEmail', error)
        dispatch(addFlashMessage('error', error.message))
      })
  }
}

export function confirmEmail() {
  return (dispatch: Dispatch) => {
    if (EMAIL_CONFIRMATION_STATUS === 'WARNING') dispatch(confirmEmailWarning())
    if (EMAIL_CONFIRMATION_STATUS === 'FAILURE') dispatch(confirmEmailFailure())
    if (EMAIL_CONFIRMATION_STATUS === 'SUCCESS') dispatch(confirmEmailSuccess())

    dispatch(push('/login'))
  }
}
