import { camelizeKeys } from 'humps'

import getCustomerAPI from '~/utils/api/get-customer-api'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import type { RedeemableVoucher } from '~/types'

export const LOAD_REDEEMABLE_VOUCHERS_START = 'redeemableVouchers/LOAD_START'
export const LOAD_REDEEMABLE_VOUCHERS_SUCCESS =
  'redeemableVouchers/LOAD_SUCCESS'
export const LOAD_REDEEMABLE_VOUCHERS_FAIL = 'redeemableVouchers/LOAD_FAIL'
export type RedeemableVoucherActions =
  | {
      type: 'redeemableVouchers/LOAD_START'
    }
  | {
      type: 'redeemableVouchers/LOAD_SUCCESS'
      data: RedeemableVoucher[]
    }
  | {
      type: 'redeemableVouchers/LOAD_FAIL'
    }

export function loadRedeemableVouchers({
  reload,
}: {
  reload?: boolean
} = {}) {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const {
      redeemableVouchers: { loaded, loading },
    } = getState()

    if (loading || (loaded && !reload)) return

    dispatch({
      type: LOAD_REDEEMABLE_VOUCHERS_START,
    })

    try {
      const api = getCustomerAPI()
      const response = await api.getRedeemableVouchers()

      if (response.ok) {
        const { data } = await response.json()
        dispatch({
          type: LOAD_REDEEMABLE_VOUCHERS_SUCCESS,
          data: data.map((redeemableVoucher) =>
            camelizeKeys(redeemableVoucher),
          ),
        })
      } else {
        throw new HTTPError('failed: GET', response, await response.text())
      }
    } catch (err) {
      reportGroupedError('loadRedeemableVouchers', err)
      dispatch({
        type: LOAD_REDEEMABLE_VOUCHERS_FAIL,
      })
    }
  }
}
