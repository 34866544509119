import { RESOURCE_UPDATE } from '../actions/resources'

function updateResource(state, { path, resource }) {
  const currentResource = state[path]
  return { ...state, [path]: { ...currentResource, ...resource } }
}

export default function resources(
  state: Record<string, any> = {},
  action: Record<string, any>,
) {
  switch (action.type) {
    case RESOURCE_UPDATE:
      // @ts-expect-error TS2345
      return updateResource(state, action)
  }

  return state
}
