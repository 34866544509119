import {
  TOGGLE_MINOR_CONSENT,
  ALERT_MINOR_CONSENT,
  RESET_MINOR_CONSENT,
} from '~/actions/minor-consent'

import type { AppActions } from '~/actions/types'

export type MinorConsentState = {
  accepted: boolean
  alert: boolean
}

const initialState = {
  accepted: false,
  alert: false,
}

export default (
  state: MinorConsentState = initialState,
  action: AppActions,
) => {
  switch (action.type) {
    case TOGGLE_MINOR_CONSENT:
      return { ...state, accepted: !state.accepted }
    case ALERT_MINOR_CONSENT:
      return { ...state, alert: action.value }
    case RESET_MINOR_CONSENT:
      return { ...initialState }
    default:
      return state
  }
}
